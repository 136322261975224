<template>
  <div>
    <v-progress-circular
        v-if="loading"
        :size="50"
        color="primary"
        indeterminate
    ></v-progress-circular>

    <v-alert v-if="errorMessage" type="error">
      {{ errorMessage }}
    </v-alert>

    <div v-if="status" @click="updateContent">
      <v-card v-for="client in status" :key="client.client" class="text-left">
        <v-card-title class="pa-2 pb-0">
          {{ client.client }}
        </v-card-title>
        <v-card-text>
          <v-card v-for="host in client.hosts" :key="host.host" class="d-inline-flex mt-2">
            <v-card>
              <v-card-title class="cns-host pa-1">
                <v-icon small color="red darken-2" v-if="host.class === 'critical'" class="pr-1">mdi-alert</v-icon>
                <v-icon small color="yellow darken-2" v-if="host.class === 'warning'" class="pr-1">mdi-alert-outline
                </v-icon>
                {{ host.host }}
              </v-card-title>
            </v-card>
            <v-card v-for="service in host.services" :key="service.service" class="d-inline-flex ml-2" outlined>
              <v-card-text class="pa-1">
                <v-icon small color="red darken-2" v-if="service.class === 'critical'">mdi-alert</v-icon>
                <v-icon small color="yellow darken-2" v-if="service.class === 'warning'">mdi-alert-outline</v-icon>
                {{ service.service }} <small>[{{ service.duration }}]</small>
              </v-card-text>
            </v-card>
          </v-card>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="!loading && !status.length" @click="updateContent">
      <v-icon large>mdi-check</v-icon>
      no errors
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ClientsStatus',
  data: () => ({
    loading: true,
    status: null,
    errorMessage: null,
    timeHidden: null,
  }),
  created() {
    this.updateContent()

    document.addEventListener('visibilitychange', () => {
      let state = document.visibilityState;
      if (state === 'hidden') {
        this.timeHidden = (new Date()).getTime()
      } else if (state === 'visible' && this.timeHidden) {
        let lastSec = parseInt(this.timeHidden / 1000)
        let nowSec = parseInt((new Date()).getTime() / 1000)
        if ((nowSec - lastSec) > 10) {
          this.updateContent()
        }
      }
    })
  },
  methods: {
    async updateContent() {
      await this.$store.dispatch('updateToken')
      let token = this.$store.getters.idToken
      axios
          //.get('http://localhost/cnsmon/backend/', {'headers': {'Authorization': 'Bearer ' + token}})
          .get('https://cnsmon.cnsweb.nl/backend/', {'headers': {'Authorization': 'Bearer ' + token}})
          .then(response => {
            this.loading = false;
            if (response.data.error) {
              this.errorMessage = response.data.error
            } else {
              this.status = response.data
            }
            let d = new Date();
            this.$emit('newUpdateTime', d.toLocaleTimeString())
          })
          .catch(error => {
            console.log(error)
          })
    }
  }
}
</script>

<style lang="css">
.cns-host {
  font-size: 0.9rem !important;
  line-height: 1.375rem !important;
  white-space: nowrap;
}
</style>