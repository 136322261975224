<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-progress-circular
            v-if="!loggedin"
            :size="50"
            color="primary"
            indeterminate
        ></v-progress-circular>
        <div v-if="loggedin">
          <ClientsStatus @newUpdateTime="changeUpdateTime"/>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ClientsStatus from './ClientsStatus';

export default {
  name: 'ForceLoginHome',

  components: {
    ClientsStatus,
  },

  data: () => ({
    username: null,
    homeUpdateTime: null,
  }),
  created() {
    this.$store.dispatch('login')
  },
  methods: {
    changeUpdateTime(newValue) {
      this.$emit('newUpdateTime', newValue)
    },
  },
  computed: {
    loggedin() {
      return this.$store.getters.isLoggedIn;
    },
  },
}
</script>