<template>
  <v-app>
    <v-app-bar
        app
        color="primary"
        dark
    >
      <div class="d-flex align-center">
        <v-toolbar-title>CNS monitor</v-toolbar-title>
      </div>

      <v-spacer></v-spacer>
      <span v-if="updateTime">Updated: {{ updateTime }}</span>

    </v-app-bar>

    <v-main>
      <ForceLoginHome @newUpdateTime="changeUpdateTime"/>
    </v-main>
  </v-app>
</template>

<script>
import ForceLoginHome from "@/components/ForceLoginHome";

export default {
  name: 'App',
  data: () => ({
    updateTime: null,
  }),
  components: {
    ForceLoginHome,
  },
  methods: {
    changeUpdateTime(newValue) {
      this.updateTime = newValue
    },
  }
}
</script>