import Vue from 'vue'
import Vuex from 'vuex'
import { PublicClientApplication } from '@azure/msal-browser';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        msalConfig: {
            auth: {
                clientId: 'c1d69dbb-cd2d-42a8-b873-50c5db684832',
                authority: 'https://login.microsoftonline.com/0ebc4dba-f1ab-4cac-8b0d-c4ecb95c0738',
            }
        },
        msal: null,
        msalAccount: '',
        idToken: '',
        idTokenRefresh: null,
    },
    mutations: {
        authSuccess(state, msalAccount) {
            state.msalAccount = msalAccount
        },
        logout(state) {
            state.idToken = ''
        },
        tokenUpdated(state, idToken) {
            state.idToken = idToken
            state.idTokenRefresh = parseInt((new Date()).getTime() / 1000)
        }
    },
    actions: {
        login({commit, state, dispatch}) {
            return new Promise((resolve) => {
                if (!state.msal) {
                    state.msal = new PublicClientApplication(
                        state.msalConfig,
                    );
                }

                state.msal.handleRedirectPromise().then(response => {
                    if (response !== null) {
                        location.reload();
                        return null;
                    } else {
                        // In case multiple accounts exist, you can select
                        const currentAccounts = state.msal.getAllAccounts();

                        if (currentAccounts.length === 0) {
                            state.msal.loginRedirect({});
                        } else if (currentAccounts.length > 0) {
                            commit('authSuccess', currentAccounts[0]);
                            dispatch('updateToken')
                            resolve();
                        }
                    }
                });
            })
        },
        updateToken({commit, state, dispatch}) {
            return new Promise((resolve) => {
                if (state.idTokenRefresh) { // is token still valid?
                    let nowSec = parseInt((new Date()).getTime() / 1000)
                    if ((nowSec - state.idTokenRefresh) < 1800) { // still valid, no update
                        resolve()
                        return
                    }
                }

                if (!state.msal) {
                    state.msal = new PublicClientApplication(
                        state.msalConfig,
                    );
                }

                const accessTokenRequest = {
                    account: state.msalAccount
                }

                state.msal.acquireTokenSilent(accessTokenRequest)
                    .then(resp => {
                        if (resp.idToken) {
                            commit('tokenUpdated', resp.idToken)
                            resolve()
                        } else {
                            console.error("error: ", resp)
                        }
                    })
                    .catch(err => {
                        if (err.errorCode === 'no_tokens_found') {
                            console.log('Token update failed. Logging in again...')
                            dispatch('login')
                        } else {
                            console.error(err.errorCode, err)
                        }
                    })
            })
        },
        logout({commit}) {
            return new Promise((resolve) => {
                commit('logout')
                resolve()
            })
        },
    },
    modules: {},
    getters: {
        isLoggedIn: state => !!state.idToken,
        msalAccount: state => state.msalAccount,
        idToken: state => state.idToken,
    }
})